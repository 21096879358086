import { useState } from 'react';
import styles from './MultichainSection.module.scss'
import copyImg from '../../../images/document.svg'
import metamaskImg from '../../../images/metamask.svg'
import shidenBannerPc from '../../../images/shiden.svg'
import polygonBannerPc from '../../../images/polygon.svg'
import ethereumBannerPc from '../../../images/ethereum.svg'
import gnosisBannerPc from '../../../images/gnosis.svg'
import avalancheBannerPc from '../../../images/avalanche.svg'
import astarBannerPc from '../../../images/astar.svg'
import { IconButton, Tooltip } from "@mui/material";

interface Window {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ethereum: any;
  location: any;
}

declare const window: Window;

const MultichainSection = () => {

  const [openTip_ETH, setOpenTip_ETH] = useState(false);
  const [openTip_Matic, setOpenTip_Matic] = useState(false);
  const [openTip_xDai, setOpenTip_xDai] = useState(false);
  const [openTip_shiden, setOpenTip_shiden] = useState(false);
  const [openTip_avalanche, setOpenTip_avalanche] = useState(false);
  const [openTip_astar, setOpenTip_astar] = useState(false);

  const tokenDecimals = 18;
  const tokenImage = "https://jpyc.jp/static/media/jpyc.0d1e5d3f.png";

  const addToken = async (tokenAddress: string, tokenSymbol: string, chainId: string) => {
    if (!window.ethereum) {
      return;
    } else if (window.ethereum.chainId !== chainId) {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainId }],
      });
    } else {
      try {
        await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
              image: tokenImage,
            },
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const copy = (text: string) => {
    navigator.clipboard.writeText(text);
  }

  return (
    <section className={styles.multichain}>
      <div className={styles.multichainWrapper}>
      <h3 className={styles.multichainText}>Multi-chain</h3>
      <h2 className={styles.multichainTitle}>対応チェーン</h2>
        <div className={styles.chainList}>
          <div className={styles.chainWrapper}>
            <h4>Ethereum</h4>
            <p>コントラクトアドレス</p>
            <div className={styles.chainAddress}>
              <p className={styles.contractAddress}>0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB</p>
              <Tooltip 
                disableHoverListener   
                title="Copied!" 
                open={openTip_ETH} 
                onClose={() => setOpenTip_ETH(false)}
              >
                <IconButton onClick={() => {
                    setOpenTip_ETH(true);
                    copy("0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB");
                }}>
                  <img src={copyImg} alt="copy" />
                </IconButton>
              </Tooltip>
              <img onClick={() => addToken("0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB", "JPYC", "0x1")} src={metamaskImg} alt="metamask-img" />
            </div>
          </div>
          <div className={styles.chainWrapper}>
            <h4>Polygon</h4>
            <p>コントラクトアドレス</p>
            <div className={styles.chainAddress}>
              <p className={styles.contractAddress}>0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB</p>
              <Tooltip 
                disableHoverListener   
                title="Copied!" 
                open={openTip_Matic} 
                onClose={() => setOpenTip_Matic(false)}
              >
                <IconButton onClick={() => {
                    setOpenTip_Matic(true);
                    copy("0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB");
                }}>
                  <img src={copyImg} alt="copy" />
                </IconButton>
              </Tooltip>
              <img onClick={() => addToken("0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB", "JPYC", "0x89")} src={metamaskImg} alt="metamask-img" />
            </div>
          </div>
          <div className={styles.chainWrapper}>
            <h4>Gnosis</h4>
            <p>コントラクトアドレス</p>
            <div className={styles.chainAddress}>
              <p className={styles.contractAddress}>0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB</p>
              <Tooltip 
                disableHoverListener   
                title="Copied!" 
                open={openTip_xDai} 
                onClose={() => setOpenTip_xDai(false)}
              >
                <IconButton onClick={() => {
                    setOpenTip_xDai(true);
                    copy("0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB");
                }}>
                  <img src={copyImg} alt="copy" />
                </IconButton>
              </Tooltip>
              <img onClick={() => addToken("0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB", "JPYC", "0x64")} src={metamaskImg} alt="metamask-img" />
            </div>
          </div>
          <div className={styles.chainWrapper}>
            <h4>Shiden</h4>
            <p>コントラクトアドレス</p>
            <div className={styles.chainAddress}>
              <p>0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB</p>
              <Tooltip 
                disableHoverListener   
                title="Copied!" 
                open={openTip_shiden} 
                onClose={() => setOpenTip_shiden(false)}
              >
                <IconButton onClick={() => {
                    setOpenTip_shiden(true);
                    copy("0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB");
                }}>
                  <img src={copyImg} alt="copy" />
                </IconButton>
              </Tooltip>
              <img onClick={() => addToken("0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB", "JPYC", "0x150")} src={metamaskImg} alt="metamask-img" />
            </div>
          </div>
          <div className={styles.chainWrapper}>
            <h4>Avalanche</h4>
            <p>コントラクトアドレス</p>
            <div className={styles.chainAddress}>
              <p>0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB</p>
              <Tooltip 
                disableHoverListener   
                title="Copied!" 
                open={openTip_avalanche} 
                onClose={() => setOpenTip_avalanche(false)}
              >
                <IconButton onClick={() => {
                    setOpenTip_avalanche(true);
                    copy("0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB");
                }}>
                  <img src={copyImg} alt="copy" />
                </IconButton>
              </Tooltip>
              <img onClick={() => addToken("0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB", "JPYC", "0xa86a")} src={metamaskImg} alt="metamask-img" />
            </div>
          </div>
          <div className={styles.chainWrapper}>
            <h4>Astar</h4>
            <p>コントラクトアドレス</p>
            <div className={styles.chainAddress}>
              <p>0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB</p>
              <Tooltip 
                disableHoverListener   
                title="Copied!" 
                open={openTip_astar} 
                onClose={() => setOpenTip_astar(false)}
              >
                <IconButton onClick={() => {
                    setOpenTip_astar(true);
                    copy("0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB");
                }}>
                  <img src={copyImg} alt="copy" />
                </IconButton>
              </Tooltip>
              <img onClick={() => addToken("0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB", "JPYC", "0x250")} src={metamaskImg} alt="metamask-img" />
            </div>
          </div>
        </div>
        </div>
      <div className={styles.coinBannerWrapper}>
          <div className={styles.bannerBox}>
            <img className={styles.shidenBanner} src={shidenBannerPc} alt="shiden-banner" />
          </div>
          <div className={styles.bannerBox}>
            <img className={styles.polygonBanner} src={polygonBannerPc} alt="polygon-banner" />
          </div>
          <div className={styles.bannerBox}>
            <img className={styles.avalancheBanner} src={avalancheBannerPc} alt="avalanche-banner" />
          </div>
          <div className={styles.bannerBox}>
            <img className={styles.ethereumBanner} src={ethereumBannerPc} alt="ethereum-banner" />
          </div>
          <div className={styles.bannerBox}>
            <img className={styles.gnosisBanner} src={gnosisBannerPc} alt="gnosis-banner" />
          </div>
          <div className={styles.bannerBox}>
            <img className={styles.astarBanner} src={astarBannerPc} alt="astar-banner" />
          </div>
        </div>
    </section>
  )
};

export default MultichainSection;
